import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      {...props}
    >
      <defs>
        <path
          id="search-close-icon-a"
          d="M5.6 0a.7.7 0 01.7.7l-.001 4.199L10.5 4.9a.7.7 0 01.694.605l.006.095a.7.7 0 01-.7.7l-4.201-.001L6.3 10.5a.7.7 0 01-.605.694L5.6 11.2a.7.7 0 01-.7-.7l-.001-4.201L.7 6.3a.7.7 0 01-.694-.605L0 5.6a.7.7 0 01.7-.7l4.199-.001L4.9.7a.7.7 0 01.605-.694z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 .7)">
        <circle cx="10.15" cy="10.15" r="10.15" fill="#FFF" />
        <use
          fill="#8AE560"
          fillRule="nonzero"
          transform="rotate(45 2.135 13.47)"
          xlinkHref="#search-close-icon-a"
         />
      </g>
    </svg>
  )
}

export default Icon
