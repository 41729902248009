import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 802 703"
      {...props}
    >
      <path
        fill="#6B75FF"
        fillRule="evenodd"
        d="M617.478 3.923c58.17-6.322 86.263-7.226 117 14.451 30.737 21.678 69.737 76.172 67.424 115.612-2.314 39.44-80.314 80.687-81.305 121.03-.992 40.345 71.39 76.473 75.356 121.032 3.966 44.558-34.373 96.042-51.56 146.32-17.186 50.28-2.313 130.967-51.56 155.354-49.245 24.387-173.847-12.946-243.914-9.032-70.068 3.914-115.348 43.053-176.492 32.515-61.144-10.537-161.288-55.397-190.373-95.74-29.085-40.344 29.415-101.16 15.865-146.321C84.368 413.983 7.029 391.402.749 334.5c-6.28-56.903 28.093-165.59 59.492-216.772 31.398-51.182 74.694-80.085 128.898-90.321C243.342 17.17 314.732 59.62 385.46 56.31c70.729-3.312 173.847-46.064 232.017-52.387z"
       />
    </svg>
  );
}

export default Icon;
