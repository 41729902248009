import { useEffect, useRef } from "react"

export const menuItem = [
  {
    name: 'Features',
    to: '/',
    list: [
      { to: '/features/resource-planning', name: 'Resource Planning' },
      { to: '/features/timesheet', name: 'Timesheet' },
      { to: '/features/time-approval', name: 'Time Approval' },
      { to: '/features/invoicing', name: 'Invoicing' }
    ]
  },
  { name: 'Pricing', to: '/pricing', },
  { name: 'Contact Us', to: '/contact-us' },
  { name: 'Login', to: '/' }
]

export const backgroundVariants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 1px 1px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(0px at 1px 1px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
}

export const listVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export const useDimensions = ref => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth;
    dimensions.current.height = ref.current.offsetHeight;
  }, []) // eslint-disable-line

  return dimensions.current;
}