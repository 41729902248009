import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          id="prefix_chat-icon__a"
          d="M15.24 36.332a1.994 1.994 0 011.574.063A16.627 16.627 0 0024 38c6.086-.002 11.631-3.24 14.327-8.335l.004-.008A14.028 14.028 0 0040 23l.003-.883C39.56 14.525 33.097 8.422 25.06 8h-1.064a16.603 16.603 0 00-7.188 1.609C11.399 12.16 8.002 17.349 8 23.006a14.001 14.001 0 001.67 6.65c.208.39.281.838.209 1.274l-1.33 7.956 6.69-2.554zM24.005 42c-2.8.007-5.565-.551-8.115-1.635l-9.177 3.503c-1.444.552-2.94-.673-2.686-2.198l1.798-10.75A17.966 17.966 0 014 23c.003-7.224 4.312-13.805 11.092-17.005A20.587 20.587 0 0124 4l1.163.003C35.299 4.53 43.432 12.212 44 22v.995a18.008 18.008 0 01-2.14 8.544C38.46 37.96 31.546 41.997 24.005 42z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fillRule="nonzero" d="M0 0h48v48H0z" />
        <mask id="prefix_chat-icon__b" fill="#fff">
          <use xlinkHref="#prefix_chat-icon__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#prefix_chat-icon__a" />
        <g fill="#6B75FF" mask="url(#prefix_chat-icon__b)">
          <path d="M0 0h48v48H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
