import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import s from './footer.module.scss'

const featuresList = [
  { path: '/features/resource-planning', name: 'Resource Planning' },
  { path: '/features/timesheet', name: 'Timesheet' },
  { path: '/features/time-approval', name: 'Time Approval' },
  { path: '/features/invoicing', name: 'Invoicing' }
]

const plansList = [
  { path: '/pricing', name: 'Pricing' },
  { path: '/payment', name: 'payment' }
]

const companyList = [
  // { path: '/about', name: 'About' },
  { path: '/contact-us', name: 'Contact Us' },
  // { path: '/legal', name: 'Legal' }
]

const renderList = list => {
  return (
    <ul className={s.list}>
      {list.map(({ path, name }) => (
        <li key={name}>
          <AniLink paintDrip duration={1} hex="#6b75ff" className={s.listLink} to={path}>
            {name}
          </AniLink>
        </li>
      ))}
    </ul>
  )
}

const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className="container">
        <div className="row">
          <div className="col-sm-2 col-lg-6 col-xl-7">
            <div className={s.left}>
              <h4 className={s.title}>BisFlow</h4>
              <p className={s.copyright}>Copyright 2020</p>
            </div>
          </div>

          <div className="col-sm-10 col-lg-6 col-xl-5">
            <div className="row">
              <div className="col-sm-4">
                <h5 className={s.listTitle}>Features</h5>
                {renderList(featuresList)}
              </div>

              <div className="col-sm-4">
                <h5 className={s.listTitle}>Plans</h5>
                {renderList(plansList)}
              </div>

              <div className="col-sm-4">
                <h5 className={s.listTitle}>Company</h5>
                {renderList(companyList)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
