import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 737"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
  fill="#6B75FF"
  d="M0-39v413.525c136.614 250.468 342.982 364.35 619.106 341.644C895.229 693.464 1336.86 555.015 1944 300.823V-39H0z"
  opacity="0.346"
  />
        <path
  fill="#6B75FF"
  fillOpacity="0.27"
  d="M0-39h2440l-20.586 226.391C1336.242 633.636 663.926 808.141 402.467 710.905 141.007 613.67 6.852 503.28 0 379.735V-39z"
  />
        <path
  fill="#6B75FF"
  d="M0-39v408.244c41.734 180.343 214.167 278.762 517.3 295.255C820.433 680.993 1296 561.522 1944 306.087V-39H0z"
  />
        <path
  fill="#7680FF"
  d="M1875.11-267.444c-66.961 21.97-90.594 45.965-92.564 84.991-1.97 39.027 87.64 102.916 80.747 149.17-6.893 46.253-118.494 72.271-122.105 128.354-3.61 56.083 45.297 178.078 100.441 208.143 55.145 30.065 167.73-34.98 230.425-27.752 62.694 7.227 98.472 88.46 145.738 71.115 47.267-17.345 144.754-117.947 137.86-175.187-6.892-57.239-170.684-117.37-179.218-168.249-8.534-50.879 126.7-84.124 128.014-137.027 1.312-52.903-48.908-158.42-120.136-180.39-71.228-21.971-242.241 24.861-309.202 46.832z"
  />
        <path
  fill="#D7DAFF"
  d="M0-40h324l-.114 4.412c-5.156 29.189-65.724 74.767-93.623 91.866-34.338 21.044-89.076-.79-118.767 17.361-29.692 18.151-32.924 75.234-59.384 91.543-9.922 6.116-24.134 8.348-38.864 8.818L0 173.965V-40z"
  opacity="0.111"
  />
      </g>
    </svg>
  );
}

export default Icon;
