import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 869 709"
      {...props}
    >
      <path
        fill="#6B75FF"
        fillRule="evenodd"
        d="M585.816 45.501c259.631-159.84 311.391 140.332 270.856 300.707-50.52 199.885 78.663 291.653-43.035 354.296-126.3 65.013-225.433-267.333-533.459-176.92C-90.688 632.44-104.34 172.348 305.025 167.391c103.043-1.247 169.934-53.642 280.79-121.89z"
       />
    </svg>
  );
}

export default Icon;
