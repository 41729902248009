import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <path
          id="prefix_close_icon__a"
          d="M7.61 5.724l8.39 8.39 8.39-8.39a1.334 1.334 0 011.886 1.885L17.886 16l8.39 8.392a1.334 1.334 0 01-1.885 1.885l-8.392-8.39-8.39 8.39a1.334 1.334 0 01-1.885-1.885L14.114 16l-8.39-8.39a1.334 1.334 0 011.885-1.885z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h32v32H0z" />
        <mask id="prefix_close_icon__b" fill="#fff">
          <use xlinkHref="#prefix_close_icon__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#prefix_close_icon__a" />
        <g fill="#6B75FF" mask="url(#prefix_close_icon__b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
