import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="search_icon_a"
          d="M10.477 0c5.787 0 10.477 4.69 10.477 10.477 0 2.474-.857 4.748-2.291 6.54l4.279 4.278a1.164 1.164 0 01-1.647 1.647l-4.277-4.28a10.433 10.433 0 01-6.54 2.292C4.69 20.954 0 16.264 0 10.477 0 4.691 4.69 0 10.477 0zm0 2.328a8.149 8.149 0 105.658 14.014 1.034 1.034 0 01.207-.208 8.149 8.149 0 00-5.864-13.806z"
         />
      </defs>
      <use fill="#5765CB" xlinkHref="#search_icon_a" />
    </svg>
  )
}

export default Icon
