import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 591 486"
      {...props}
    >
      <path
        fill="#6B75FF"
        fillRule="evenodd"
        d="M507.254 40.956c-45.882-16.152-139.128-54.064-209.58-36.342C227.222 22.337 133.681 94.796 84.542 147.29c-49.139 52.493-96.798 117.101-81.7 172.287 15.096 55.186 93.54 138.188 172.281 158.827 78.74 20.639 232.078-4.038 300.161-34.996 68.084-30.958 92.062-93.77 108.343-150.751 16.28-56.98 2.368-149.405-10.657-191.131s-19.833-44.418-65.716-60.57z"
       />
    </svg>
  );
}

export default Icon;
