import React, { useRef, useEffect, useState, forwardRef } from "react"
import cx from 'clsx'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { motion, useCycle } from 'framer-motion'
import Fade from 'react-reveal/Fade'

import { ArrowBottom } from 'src/components/Svg'
import { menuItem, itemVariants, useDimensions } from './utils'

import s from './header.module.scss'

const SignUpButton = ({ isFullScreen = false }) => (
  <a
    className={cx('btn btn-outline-secondary', s.signUpBtn)}
    href={`https://app.bisflow.com/signup`}
  >
    Sign Up Free
  </a>
)

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#fff"
    strokeLinecap="round"
    {...props}
  />
)

const MenuToggle = forwardRef(({ toggle, ...motionProps }, ref) => (
  <button className={cx('d-block d-lg-none', s.toggleButton)} onClick={toggle} ref={ref}>
    <motion.svg width="23" height="23" viewBox="0 0 23 23" {...motionProps}>
      <Path
        variants={{ closed: { d: "M 2 2.5 L 20 2.5" }, open: { d: "M 3 16.5 L 17 2.5" } }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{ closed: { opacity: 1 }, open: { opacity: 0 } }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{ closed: { d: "M 2 16.346 L 20 16.346" }, open: { d: "M 3 2.5 L 17 16.346" } }}
      />
    </motion.svg>
  </button>
))

const RenderSubMenuMobile = ({ name, to, list }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <li key={name} className={cx({ [s.active]: isActive })}>
      {!list ? <Link className="link-inherit d-block" to={to}>{name}</Link> : null}

      {list ? (
        <>
          <span role="presentation" onClick={() => setIsActive(!isActive)}>{name} <ArrowBottom className={s.arrowIcon} /></span>
          <ul>
            {list.map(sItem => (
              <li key={sItem.name}>
                <Link className="link-inherit d-block" to={sItem.to}>{sItem.name}</Link>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </li>
  )
}

const getDocumentScrollHeight = () => typeof document !== 'undefined' ? document.body.scrollHeight : 2000

const Index = ({ siteTitle }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [visibleFullScreen, setVisibleFullScreen] = useState(false)
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [bodyHeight, setBodyHeight] = useState(2000)

  useEffect(() => {
    setBodyHeight(getDocumentScrollHeight())

    if (isOpen) setVisibleFullScreen(true)
    else setTimeout(() => setVisibleFullScreen(false), 200)
  }, [isOpen])

  return (
    <Fade top cascade>
      <header className={s.header}>
        <div className="container">
          <div className={s.inner}>
            <div>
              <AniLink paintDrip duration={1} hex="#6b75ff" className={s.homeLink} to="/">
                <h2 className={s.siteName}>
                  <span className={s.bis}>Bis</span>
                  <span>Flow</span>
                </h2>
              </AniLink>
            </div>

            <div className={cx('d-none d-lg-flex', s.right)}>
              <ul className={s.menu}>
                {menuItem.map(({ name, to, list }) => (
                  <li key={name}>
                    {!list ? <AniLink paintDrip duration={1} hex="#6b75ff" to={to}>{name}</AniLink> : null}
                    {list ? (
                      <>
                        <span>{name} <ArrowBottom className={s.arrowIcon} /></span>
                        <ul>
                          {list.map(sItem => (
                            <li key={sItem.name}>
                              <AniLink paintDrip duration={1} hex="#6b75ff" to={sItem.to}>{sItem.name}</AniLink>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : null}
                  </li>
                ))}
              </ul>

              <SignUpButton />
            </div>

            <MenuToggle
              initial={false}
              animate={isOpen ? 'open' : 'closed'}
              custom={height}
              ref={containerRef}
              toggle={() => toggleOpen()}
            />

            <nav className={cx('d-flex d-lg-none', s.nav, { [s.showList]: visibleFullScreen })}>
              <div
                ref={containerRef}
                className={cx(s.background, { [s.backgroundActive]: isOpen })}
                style={{ height: bodyHeight }}
              >
                <ul className={s.list}>
                  {menuItem.map(({ name, to, list }) => (
                    <RenderSubMenuMobile key={name} name={name} to={to} list={list} />
                  ))}

                  <li variants={itemVariants}>
                    <SignUpButton isFullScreen />
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </Fade>
  )
}

Index.propTypes = {
  siteTitle: PropTypes.string,
}

Index.defaultProps = {
  siteTitle: ``,
}

export default Index
