import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 931"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#D7DAFF"
          d="M0 0v708.5c276.959-90.076 542.792-124.076 797.5-102C1052.208 628.576 1426.708 736.743 1921 931V0H0z"
         />
        <path
          fill="#6B75FF"
          d="M0 0v666c276.959-61.743 542.792-81.576 797.5-59.5 254.708 22.076 629.208 97.41 1123.5 226V0H0z"
         />
      </g>
    </svg>
  );
}

export default Icon;
