import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 639 790"
      {...props}
    >
      <path
        fill="#7680FF"
        fillRule="evenodd"
        d="M139.344 62.061c-69.575 25.945-94.13 54.279-96.176 100.364-2.047 46.086 91.06 121.53 83.898 176.15-7.162 54.619-123.119 85.342-126.87 151.57-3.752 66.226 47.064 210.285 104.36 245.788 57.297 35.503 174.276-41.306 239.417-32.772 65.14 8.535 102.315 104.46 151.426 83.978 49.11-20.482 150.402-139.28 143.24-206.872-7.162-67.592-177.345-138.598-186.212-198.68-8.868-60.082 131.645-99.34 133.009-161.811C586.8 157.305 534.619 32.703 460.612 6.759 386.604-19.186 208.918 36.117 139.344 62.06z"
       />
    </svg>
  );
}

export default Icon;
